<template>
    <div>
        <div class="row justify-content-center" v-show="courseExist">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <h1 class="mt-4 font-weight-bolder"><i class="fas fa-edit mr-3"></i>Edit Courses Test</h1>
                <hr>
                <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-5 p-auto">
                        <div class="card w-100 p-0 shadow-xss border-0 rounded-lg overflow-hidden mr-1 p-3">
                            <form @submit.prevent="updateCourse" class="row p-2">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                    <div class="row p-auto">
                                        <div class="col-xl-12">
                                            <div class="avatar-upload"  v-show="!edit">
                                                <div class="text-center p-2">
                                                    <label for="avatar">
                                                        <img width="200" :src="course.banner ?  course.banner : 'https://www.gravatar.com/avatar/default?s=200&r=pg&d=mm'" />
                                                    </label>
                                                </div>
                                                <div class="text-center p-2">
                                                    <file-upload
                                                    extensions="jpg,jpeg,png"
                                                    accept="image/png,image/jpeg,image/jpg"
                                                    name="avatar"
                                                    class="btn btn-current"
                                                    :drop="!edit"
                                                    v-model="files"
                                                    @input-filter="inputFilter"
                                                    @input-file="inputFile"
                                                    ref="upload" required>
                                                    Upload Banner
                                                    </file-upload>
                                                </div>
                                            </div>

                                            <div class="avatar-edit" v-show="files.length && edit">
                                                <div class="avatar-edit-image" v-if="files.length">
                                                    <cropper
                                                        class="cropper"
                                                        ref="cropper"
                                                        :src="files[0].url"
                                                        :stencil-props="{
                                                            handlers: {},
                                                            movable: false,
                                                            scalable: false,
                                                            aspectRatio: 4 / 3,
                                                        }"
                                                        :resize-image="{
                                                            adjustStencil: false
                                                        }"
                                                        image-restriction="stencil"
                                                    ></cropper>
                                                </div>
                                                <div class="text-center p-4">
                                                    <button type="button" class="btn btn-default" @click.prevent="$refs.upload.clear">Cancel</button>
                                                    <button type="submit" class="btn btn-current" @click.prevent="editSave">Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">

                                        <div class="col-lg-12 form-group mb-3">
                                            <label for="">Course Name</label>
                                            <input v-model="course.name" type="text" required class="form-control text-grey-900 font-xsss fw-600" placeholder="Enter your Course Name">
                                        </div>
                                        <div class="col-lg-6 form-group mb-3">
                                            <label for="">Course Category</label>
                                            <select @change="changeCategory()" v-model="course.category" class="form-control text-grey-900 font-xsss fw-600" required>
                                                <option value="" disabled selected>-- Select your Course Category --</option>
                                                <option value="SHINE">SHINE</option>
                                                <option value="GLORY">GLORY</option>
                                                <option value="TRIUMPH">TRIUMPH</option>
                                                <option value="EXCLUSIVE">EXCLUSIVE</option>
                                            </select>
                                        </div>
                                        <div class="col-lg-6 form-group mb-3">
                                            <label for="">Level</label>
                                            <select v-model="course.level" class="form-control text-grey-900 font-xsss fw-600" required>
                                                <option value="" disabled selected>-- Select your Course Level --</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4" v-if="course.category == 'SHINE' || course.category == 'EXCLUSIVE'">4</option>
                                                <option value="5" v-if="course.category == 'SHINE'">5</option>
                                            </select>
                                        </div>
                                        <div class="col-lg-12 form-group mb-3">
                                            <label for="">Description</label>
                                            <textarea v-model="course.description" type="text" required class="form-control text-grey-900 font-xsss fw-600" placeholder="Enter your Course Description"></textarea>                       
                                        </div>
                                        <div class="col-lg-12 form-group mb-3">
                                            <label for="">Objective</label>
                                            <vue-editor v-model="course.objective" required/>
                                        </div>
                                        <div class="col-12"><hr></div>
                                        <div class="col-lg-12 form-group">
                                            <div class="form-check">
                                                <input type="checkbox" v-model="course.has_special" class="form-check-input mt-2" id="checkSpecial">
                                                <a href="javascript:void(0)" class="form-check-label font-xsss fw-500 text-grey-700" for="checkSpecial">Has Special Course</a>
                                            </div>
                                        </div>
                                        <div class="col-12 mb-2" v-if="course.has_special">
                                            <ul class="nav nav-tabs">
                                                <li class="nav-item mr-1">
                                                    <a class="nav-link fw-600 font-xs text-primary" :class="{'active': tab == 1}" href="javascript:void(0)" @click="tab = 1">Regular</a>
                                                </li>
                                                <li class="nav-item">
                                                    <a class="nav-link fw-600 font-xs text-current" :class="{'active': tab == 2}" href="javascript:void(0)" @click="tab = 2">Special Feature</a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="col-12">
                                            <div class="row" v-if="tab == 1">
                                                <div class="col-lg-12 form-group mb-3">
                                                    <label for="">Price All Term</label>
                                                    <InputCurrency :className="'form-control text-grey-900 font-xsss fw-600'" v-model="course.price" :value="course.price" :place-holder="'Enter your Course Price'" :is-required="true"></InputCurrency>
                                                </div>
                                                <div class="col-lg-12">
                                                    <hr>
                                                    <label for="" class="text-primary font-xs fw-600">Terms Price</label>
                                                </div>
                                                <div class="col-lg-12 form-group">
                                                    <div class="form-check">
                                                        <input type="checkbox" v-model="course.cycles[0]" class="form-check-input mt-2" id="checkCycle">
                                                        <a href="javascript:void(0)" class="form-check-label font-xsss fw-500 text-grey-700" for="checkCycle">Term 1</a>
                                                    </div>
                                                    <InputCurrency :className="'form-control text-grey-900 font-xsss fw-600'" v-model="course.price_cycle_1" :value="course.price_cycle_1" :place-holder="'Enter your Course Price Cycle 1'" :is-required="true"></InputCurrency>
                                                </div>
                                                <div class="col-lg-12 form-group">
                                                    <label for="">Terms 1 Start Date</label>
                                                    <input v-model="course.start_date_1" type="date" required class="form-control text-grey-900 font-xsss fw-600">
                                                </div>
                                                <div class="col-lg-12 form-group">
                                                    <label for="">Terms 1 End Date</label>
                                                    <input v-model="course.end_date_1" type="date" required class="form-control text-grey-900 font-xsss fw-600">
                                                </div>
                                                <div class="col-12">
                                                    <hr>
                                                </div>
                                                <div class="col-lg-12 form-group">
                                                    <div class="form-check">
                                                        <input type="checkbox" v-model="course.cycles[1]" class="form-check-input mt-2" id="checkCycle">
                                                        <a href="javascript:void(0)" class="form-check-label font-xsss fw-500 text-grey-700" for="checkCycle">Term 2</a>
                                                    </div>
                                                    <InputCurrency :className="'form-control text-grey-900 font-xsss fw-600'" v-model="course.price_cycle_2" :value="course.price_cycle_2" :place-holder="'Enter your Course Price Cycle 2'" :is-required="true"></InputCurrency>
                                                </div>
                                                <div class="col-lg-12 form-group">
                                                    <label for="">Terms 2 Start Date</label>
                                                    <input v-model="course.start_date_2" type="date" required class="form-control text-grey-900 font-xsss fw-600">
                                                </div>
                                                <div class="col-lg-12 form-group">
                                                    <label for="">Terms 2 End Date</label>
                                                    <input v-model="course.end_date_2" type="date" required class="form-control text-grey-900 font-xsss fw-600">
                                                </div>
                                                <div class="col-12">
                                                    <hr>
                                                </div>
                                                <div class="col-lg-12 form-group mb-3">
                                                    <div class="form-check">
                                                        <input type="checkbox" v-model="course.cycles[2]" class="form-check-input mt-2" id="checkCycle">
                                                        <a href="javascript:void(0)" class="form-check-label font-xsss fw-500 text-grey-700" for="checkCycle">Term 3</a>
                                                    </div>
                                                    <InputCurrency :className="'form-control text-grey-900 font-xsss fw-600'" v-model="course.price_cycle_3" :value="course.price_cycle_3" :place-holder="'Enter your Course Price Cycle 2'" :is-required="true"></InputCurrency>
                                                </div>
                                                <div class="col-lg-12 form-group">
                                                    <label for="">Terms 3 Start Date</label>
                                                    <input v-model="course.start_date_3" type="date" required class="form-control text-grey-900 font-xsss fw-600">
                                                </div>
                                                <div class="col-lg-12 form-group">
                                                    <label for="">Terms 3 End Date</label>
                                                    <input v-model="course.end_date_3" type="date" required class="form-control text-grey-900 font-xsss fw-600">
                                                </div>
                                            </div>
                                            <div class="row" v-if="tab == 2">
                                                <div class="col-lg-12 form-group mb-3">
                                                    <label for="">Price All Term Special</label>
                                                    <InputCurrency :className="'form-control text-grey-900 font-xsss fw-600'" v-model="course.price_special" :value="course.price_special" :place-holder="'Enter your Course Price'" :is-required="true"></InputCurrency>
                                                </div>
                                                <div class="col-lg-12">
                                                    <hr>
                                                    <label for="" class="text-current font-xs fw-600">Terms Special Price</label>
                                                </div>
                                                <div class="col-lg-12 form-group">
                                                    <div class="form-check">
                                                        <input type="checkbox" v-model="course.cycles[0]" class="form-check-input mt-2" id="checkCycle" disabled>
                                                        <a href="javascript:void(0)" class="form-check-label font-xsss fw-500 text-grey-700" for="checkCycle">Term Special 1</a>
                                                    </div>
                                                    <InputCurrency :className="'form-control text-grey-900 font-xsss fw-600'" v-model="course.price_special_cycle_1" :value="course.price_special_cycle_1" :place-holder="'Enter your Course Price Cycle 1'" :is-required="true"></InputCurrency>
                                                </div>
                                                <div class="col-lg-12 form-group">
                                                    <label for="">Terms Special 1 Start Date</label>
                                                    <input v-model="course.start_date_1" type="date" required class="form-control text-grey-900 font-xsss fw-600" disabled>
                                                </div>
                                                <div class="col-lg-12 form-group">
                                                    <label for="">Terms Special 1 End Date</label>
                                                    <input v-model="course.end_date_1" type="date" required class="form-control text-grey-900 font-xsss fw-600" disabled>
                                                </div>
                                                <div class="col-12">
                                                    <hr>
                                                </div>
                                                <div class="col-lg-12 form-group">
                                                    <div class="form-check">
                                                        <input type="checkbox" v-model="course.cycles[1]" class="form-check-input mt-2" id="checkCycle" disabled>
                                                        <a href="javascript:void(0)" class="form-check-label font-xsss fw-500 text-grey-700" for="checkCycle">Term Special 2</a>
                                                    </div>
                                                    <InputCurrency :className="'form-control text-grey-900 font-xsss fw-600'" v-model="course.price_special_cycle_2" :value="course.price_special_cycle_2" :place-holder="'Enter your Course Price Cycle 2'" :is-required="true"></InputCurrency>
                                                </div>
                                                <div class="col-lg-12 form-group">
                                                    <label for="">Terms Special 2 Start Date</label>
                                                    <input v-model="course.start_date_2" type="date" required class="form-control text-grey-900 font-xsss fw-600" disabled>
                                                </div>
                                                <div class="col-lg-12 form-group">
                                                    <label for="">Terms Special 2 End Date</label>
                                                    <input v-model="course.end_date_2" type="date" required class="form-control text-grey-900 font-xsss fw-600" disabled>
                                                </div>
                                                <div class="col-12">
                                                    <hr>
                                                </div>
                                                <div class="col-lg-12 form-group mb-3">
                                                    <div class="form-check">
                                                        <input type="checkbox" v-model="course.cycles[2]" class="form-check-input mt-2" id="checkCycle" disabled>
                                                        <a href="javascript:void(0)" class="form-check-label font-xsss fw-500 text-grey-700" for="checkCycle">Term Special 3</a>
                                                    </div>
                                                    <InputCurrency :className="'form-control text-grey-900 font-xsss fw-600'" v-model="course.price_special_cycle_3" :value="course.price_special_cycle_3" :place-holder="'Enter your Course Price Cycle 2'" :is-required="true"></InputCurrency>
                                                </div>
                                                <div class="col-lg-12 form-group">
                                                    <label for="">Terms Special 3 Start Date</label>
                                                    <input v-model="course.start_date_3" type="date" required class="form-control text-grey-900 font-xsss fw-600" disabled>
                                                </div>
                                                <div class="col-lg-12 form-group">
                                                    <label for="">Terms Special 3 End Date</label>
                                                    <input v-model="course.end_date_3" type="date" required class="form-control text-grey-900 font-xsss fw-600" disabled>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <hr>
                                        </div>
                                        <div class="col-lg-12 form-group mb-3">
                                            <label for="">Length</label>
                                            <input v-model="course.length" type="number" required class="form-control text-grey-900 font-xsss fw-600" placeholder="Enter your Course Length">
                                        </div>
                                        <!-- <div class="col-lg-12 form-group mb-3">
                                            <label for="">Start Date</label>
                                            <input v-model="course.start_date" type="date" required class="form-control text-grey-900 font-xsss fw-600" placeholder="Enter your Course Start Date">
                                        </div> -->
                                        <div class="col-lg-6 form-group mb-3">
                                            <label for="">Start Time</label>
                                            <input v-model="course.start_time" type="time" required class="form-control text-grey-900 font-xsss fw-600" placeholder="Enter your Course Start Time">
                                        </div>
                                        <div class="col-lg-6 form-group mb-3">
                                            <label for="">End Time</label>
                                            <input v-model="course.end_time" type="time" required class="form-control text-grey-900 font-xsss fw-600" placeholder="Enter your Course End Time">
                                        </div>
                                        <div class="col-lg-12 form-group mb-3" v-if="course.point_id == ''">
                                            <div class="form-check">
                                                <input type="checkbox" v-model="usedPoint" class="form-check-input mt-2" id="exampleCheck2">
                                                <a href="javascript:void(0)" @click="usedPoint = !usedPoint" class="form-check-label font-xsss fw-500 text-grey-700" for="exampleCheck2">Add Course Point</a>
                                            </div>
                                        </div>
                                        <div class="col-12" v-if="usedPoint || course.point_id">
                                            <div class="row">
                                                <div class="col-12">
                                                    <h3 class="fw-500 font-lg text-black">Course Point</h3>
                                                </div>
                                                <div class="col-lg-6 form-group mb-3">
                                                    <label for="">Point Type</label>
                                                    <select @change="onChange()" v-model="course.type" class="form-control text-grey-900 font-xsss fw-600" :required="course.point_id">
                                                        <option value="" disabled selected>-- Select your Course Point Type --</option>
                                                        <option value="PERCENT">Percent</option>
                                                        <option value="AMOUNT">Amount</option>
                                                    </select>
                                                </div>
                                                <div class="col-lg-6 form-group mb-3">
                                                    <label for="">Point Status</label>
                                                    <select  v-model="course.point_status" class="form-control text-grey-900 font-xsss fw-600" :required="course.point_id != ''">
                                                        <option value="" disabled selected>-- Select your Course Point Status --</option>
                                                        <option :value="1">Active</option>
                                                        <option :value="0">Inactive</option>
                                                    </select>
                                                </div>
                                                <div class="col-lg-6 form-group mb-3">
                                                    <label for="">{{course.type == 'PERCENT' ? 'Point Percent' : 'Point Amount'}}</label>
                                                    <input v-model="course.point" v-if="point_type == 'PERCENT'" max="100" min="0" type="number" :required="course.point_id != ''" class="form-control text-grey-900 font-xsss fw-600" placeholder="Enter your Course Point">
                                                    <input v-model="course.point" v-else type="number" min="0" :required="course.point_id != ''" class="form-control text-grey-900 font-xsss fw-600" placeholder="Enter your Course Point">
                                                </div>
                                                <div class="col-lg-6 form-group mb-3">
                                                    <label for="">Used Limit</label>
                                                    <input v-model="course.used_limit" type="number" :required="course.point_id != ''" class="form-control text-grey-900 font-xsss fw-600" placeholder="Enter your Course Point Used Limit">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 form-group text-right my-4">
                                            <router-link to="/admin/courses" class="btn btn-outline-warning mr-4" value="Submit">Cancel</router-link>
                                            <input type="Submit" class="btn btn-current" value="Submit">
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row justify-content-center d-flex" v-show="courseNotExist">
            <div class="col-xl-12 col-lg-12 text-center py-3">
                <div class="my-3" v-show="loadCourse">
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>

                </div>
                <img :src="'/images/empty.png'" v-show="courseNotExist" alt="" width="300">
                <h1 class="text-muted" v-show="courseNotExist">Data don't exist</h1>
            </div>
        </div>
    </div>
</template>
<style  scoped>
.cropper {
  height: 600px;
  background: #DDD;
}
</style>
<script>
    import axios from 'axios'
    import { Cropper } from 'vue-advanced-cropper'
    import 'vue-advanced-cropper/dist/style.css';
    import FileUpload from 'vue-upload-component'
    import InputCurrency from '../../../components/helpers/InputCurrency.vue';
    export default {
        name: "EditCourses",
        components: {
            FileUpload,
            Cropper,
            InputCurrency
        },
        data(){
            return {
                media: process.env.VUE_APP_URL_CLOUD,
                user: {},
                tab: 1,
                edit: false,
                files:[],
                img: 'https://images.pexels.com/photos/226746/pexels-photo-226746.jpeg',
                validationErrors: '',
                usedPoint: false,
                courseExist: false,
                courseNotExist: false,
                loadCourse: true,
                paramsId: this.$route.params.idCourse,
                point_type: '',
                course: {
                    id: '',
                    banner:'',
                    name: '',
                    category: '',
                    level: '',
                    description: '',
                    price: '',
                    price_cycle_1: '',
                    price_cycle_2: '',
                    price_cycle_3: '',
                    has_special: false,
                    price_special: '',
                    price_special_cycle_1: '',
                    price_special_cycle_2: '',
                    price_special_cycle_3: '',
                    objective: '',
                    length: '',
                    // start_date: '',
                    start_date_1: '',
                    end_date_1: '',
                    start_date_2: '',
                    end_date_2: '',
                    start_date_3: '',
                    end_date_3: '',
                    start_time: '',
                    end_time: '',
                    point_id: '',
                    point: '',
                    point_status: '',
                    used_limit: '',
                    type: '',
                    cycles: [false, false, false]
                },
            }
        },
        mounted() {
            this.getCourse()
        },
        methods: {
            onChange() {
                this.course.point = 0
                this.point_type = this.course.type
            },
            changeCategory(){
                this.course.level = '';
            },
            async getCourse(){
                await axios.get(`${process.env.VUE_APP_URL_API}/admin/course?slug=` + this.paramsId, {
                    headers: {
                        Authorization: `Bearer ${localStorage.token}`
                    }
                }).then( async (res) => {
                    this.loadCourse = false
                    if (res.data.length == 0) {
                        this.courseNotExist = true
                    } else {
                        this.course = res.data
                        this.course.point_id = ''
                        this.course.point = ''
                        this.course.used_limit = ''
                        this.course.point_status = ''
                        this.course.type = ''
                        this.course.cycles = [false, false, false];
                        await axios.get(`${process.env.VUE_APP_URL_API}/core/course/point?id=${this.course.id}`, {
                            headers: {
                                Authorization: `Bearer ${localStorage.token}`
                            }
                        }).then(res => {
                            if(res.status == 200) {
                                this.course.point_id = res.data.data.id
                                this.course.point = res.data.data.amount_point
                                this.course.used_limit = res.data.data.used_limit
                                this.course.point_status = res.data.data.status
                                this.course.type = res.data.data.type
                                this.point_type = res.data.data.type
                            }
                        }).catch((err) => console.error(err.response))
                        await axios.get(`${process.env.VUE_APP_URL_API}/admin/course/cycles?slug=${this.paramsId}`, {
                            headers: {
                                Authorization: `Bearer ${localStorage.token}`
                            }
                        }).then(res => {
                            if(res.status == 200) {
                                this.course.cycles = [];
                                res.data.data.map((item) => {
                                    if(item.cycle == 1) {
                                        this.course.start_date_1 = item.start_date
                                        this.course.end_date_1 = item.end_date
                                    } else if(item.cycle == 2) {
                                        this.course.start_date_2 = item.start_date
                                        this.course.end_date_2 = item.end_date
                                    } else if(item.cycle == 3) {
                                        this.course.start_date_3 = item.start_date
                                        this.course.end_date_3 = item.end_date
                                    }
                                    this.course.cycles.push(item.active)
                                })
                            }
                        }).catch((err) => console.error(err.response))
                        this.courseExist = true
                    }
                }).catch((err) => console.error(err))
            },
            async editSave() {
                this.edit = false
                let oldFile = this.files[0]
                const result = this.$refs.cropper.getResult();
                let binStr = atob(result.canvas.toDataURL(oldFile.type).split(',')[1])
                let arr = new Uint8Array(binStr.length)
                for (let i = 0; i < binStr.length; i++) {
                    arr[i] = binStr.charCodeAt(i)
                }
                let file = new File([arr], oldFile.name, { type: oldFile.type })
                this.$refs.upload.update(oldFile.id, {
                    file,
                    type: file.type,
                    size: file.size,
                    active: true,
                })
                let data = new FormData();
                data.append('dir', 'courses/banner');
                data.append('attachment', this.files[0].file);
                data.append('_token', 'elingwaysecretkey');
                await axios.post(`${process.env.VUE_APP_URL_MEDIA}/api/storage/store-cloud`, data, {withCredentials: false}, {
                    headers: {
                        'Accept': 'application/json',
                        'content-type': 'multipart/form-data',
                        'Authorization': `Bearer ${localStorage.token}`
                    },
                }).then(res => {
                    this.files[0].response = res.data
                    this.course.banner = res.data.path
                }).catch(err => {
                    console.log(err.response)
                })
            },
            alert(message) {
                alert(message)
            },
            inputFile(newFile, oldFile) {
                if (newFile && !oldFile) {
                    this.$nextTick(function () {
                    this.edit = true
                    })
                }
                if (!newFile && oldFile) {
                    this.edit = false
                }
            },
            inputFilter(newFile, oldFile, prevent) {
                if (newFile && !oldFile) {
                    if (!/\.(jpg|jpeg|png)$/i.test(newFile.name)) {
                    this.alert('Your choice is not a picture')
                    return prevent()
                    }
                }
                if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
                    newFile.url = ''
                    let URL = window.URL || window.webkitURL
                    if (URL && URL.createObjectURL) {
                        newFile.url = URL.createObjectURL(newFile.file)
                    }
                }
            },
            async updateCourse(){
                var data = {
                    id: this.course.id,
                    banner: this.course.banner,
                    name: this.course.name,
                    category: this.course.category,
                    level: this.course.level,
                    description: this.course.description,
                    price: this.course.price,
                    price_cycle_1: this.course.price_cycle_1,
                    price_cycle_2: this.course.price_cycle_2,
                    price_cycle_3: this.course.price_cycle_3,
                    has_special: this.course.has_special,
                    price_special: this.course.price_special,
                    price_special_cycle_1: this.course.price_special_cycle_1,
                    price_special_cycle_2: this.course.price_special_cycle_2,
                    price_special_cycle_3: this.course.price_special_cycle_3,
                    objective: this.course.objective,
                    length: this.course.length,
                    // start_date: this.course.start_date,
                    start_date_1: this.course.start_date_1,
                    end_date_1: this.course.end_date_1,
                    start_date_2: this.course.start_date_2,
                    end_date_2: this.course.end_date_2,
                    start_date_3: this.course.start_date_3,
                    end_date_3: this.course.end_date_3,
                    start_time: this.course.start_time,
                    end_time: this.course.end_time,
                    point_id: this.course.point_id,
                    point: this.course.point,
                    type: this.course.type,
                    used_limit: this.course.used_limit,
                    point_status: this.course.point_status,
                    cycles: this.course.cycles,
                    _method: 'patch'
                }
                await axios.post(`${process.env.VUE_APP_URL_API}/admin/course`, data, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.token}`
                    },
                }).then(res => {
                    this.$swal({
                        toast: true,
                        title: 'Success Edit This Course !',
                        text: res.data.message,
                        icon: 'success',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                    // this.$toastr.success(res.data.message, 'Success Edit This Course !');
                    this.$router.push({name: 'GetCoursesAdmin'})
                }).catch(err => {
                    if (err.response.status == 422){
                        this.validationErrors = Object.values(err.response.data.errors);
                        // this.$toastr.error(this.validationErrors[0][0], 'Failed Edit This Course !');
                        this.$swal({
                            toast: true,
                            title: 'Failed Edit This Course !',
                            text: this.validationErrors[0][0],
                            icon: 'error',
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                        })
                    }
                    console.log(err.response)
                })
            }
        },
        computed: {
            fullpath: function() {
                return process.env.VUE_APP_URL_CLOUD + this.course.banner;
            }
        }
    }
</script>
